import { api, AppVersion, EnvironmentBanner } from '@fleet/shared';
import { Header } from 'components/Header';
import { RetailerModal } from 'components/RetailerModal';
import { TripStopsModal } from 'components/TripStopsModal';
import { getClassifications } from 'features/classification/classificationActions';
import { initialLoadingSelector } from 'features/loading/loadingSelectors';
import {
  getApplications,
  getFavoriteTrips,
  getUser,
} from 'features/user/userActions';
import {
  selectPosConfiguration,
  selectRequestorHeaderContent,
  selectShowRetailerModal,
  selectUser,
  selectUserUnits,
} from 'features/user/userSelector';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { BookingDetails } from 'routes/BookingDetails';
import { BookingSearch } from 'routes/BookingSearch';
import { SalesReports } from 'routes/salesReport/SalesReports';
import { Tickets } from 'routes/Tickets';
import { useDispatch, useSelector } from 'store/utils';
import { IS_IMS_AT } from 'utils/common';
import AuthWrapper from 'wrappers/AuthWrapper';

export const App = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const posConfiguration = useSelector(selectPosConfiguration);
  const loading = useSelector(initialLoadingSelector);
  const userUnits = useSelector(selectUserUnits);
  const retailerModalShown = useSelector(selectShowRetailerModal);
  const requestorHeaderContent = useSelector(selectRequestorHeaderContent);
  const canShowRoutes = useMemo(() => {
    return user && !retailerModalShown && !isEmpty(posConfiguration);
  }, [retailerModalShown, user, posConfiguration]);

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...(config.headers || {}),
        Requestor: requestorHeaderContent,
      },
    }));
    return () => api.interceptors.request.eject(requestInterceptor);
  }, [requestorHeaderContent]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    dispatch(getUser());
    IS_IMS_AT && dispatch(getApplications());
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (!userUnits.organizationId) return;
    dispatch(getClassifications());
    dispatch(getFavoriteTrips());
  }, [dispatch, userUnits.organizationId]);

  useEffect(() => {
    if (posConfiguration) {
      document.title = posConfiguration.pointOfSaleName;
    }
  }, [posConfiguration]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={loading}>
          <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
          <Header />
          {canShowRoutes && (
            <Switch>
              <Route exact path="/">
                <Redirect to="/search" />
              </Route>
              <Route path="/search" component={Tickets} />
              {IS_IMS_AT && (
                <Route path="/sales-reports" component={SalesReports} />
              )}
              <Route path="/bookings" component={BookingSearch} exact />
              <Route path="/booking/:id" component={BookingDetails} exact />
            </Switch>
          )}
          <TripStopsModal />
          {retailerModalShown && <RetailerModal />}
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
};
