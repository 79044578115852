import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import {
  AdditionalOffer,
  BookingComment,
  BookingDetails,
  BookingRefundOffer,
  BookingReleaseOffer,
  BookingSearchFilter,
  BookingsSearchResult,
  HistoryEvent,
} from 'dto/booking';
import {
  confirmRefund,
  confirmRelease,
  getAdditionalOffers,
  getBooking,
  getComments,
  getHistory,
  initiateRefund,
  initiateRelease,
  postBooking,
  resetAdmissionSelection,
  resetCurrentBooking,
  searchBookings,
  setBookingFilter,
  setCurrentBooking,
  setOnHoldBookingTime,
  updateBookingPartsSelection,
  updateBookingPartsSelectionBulk,
} from 'features/booking/bookingActions';
import { selectTripOffer } from 'features/trip/tripActions';

export interface BookingState {
  filter: Partial<BookingSearchFilter>;
  search?: Pagination<BookingsSearchResult>;
  current?: BookingDetails;
  selection: {
    admission: Record<string, Array<string>>;
    ancillary: Record<string, Array<string>>;
    fulfillment: Record<string, Array<string>>;
  };
  refundOffers?: Array<BookingRefundOffer>;
  releaseOffers?: Array<BookingReleaseOffer>;
  comments: Array<BookingComment>;
  history: Array<HistoryEvent>;
  additionalOffers?: Array<AdditionalOffer>;
  onHoldBookingTime?: string;
}
const initialState: BookingState = {
  filter: {},
  selection: {
    admission: {},
    ancillary: {},
    fulfillment: {},
  },
  comments: [],
  history: [],
};
export const bookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchBookings.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(setBookingFilter, (state, action) => {
      state.filter = action.payload ?? {};
    })
    .addCase(setOnHoldBookingTime, (state, action) => {
      state.onHoldBookingTime = action.payload;
    })
    .addCase(updateBookingPartsSelection, (state, action) => {
      const { passengerId, type, selection } = action.payload;
      state.selection[type][passengerId] = selection;
    })
    .addCase(updateBookingPartsSelectionBulk, (state, action) => {
      action.payload.forEach(({ passengerId, type, selection }) => {
        state.selection[type][passengerId] = selection;
      });
    })
    .addCase(initiateRefund.pending, (state) => {
      state.refundOffers = undefined;
    })
    .addCase(initiateRefund.fulfilled, (state, action) => {
      state.refundOffers = action.payload;
    })
    .addCase(initiateRelease.pending, (state) => {
      state.releaseOffers = undefined;
    })
    .addCase(initiateRelease.fulfilled, (state, action) => {
      state.releaseOffers = action.payload;
    })
    .addCase(getAdditionalOffers.fulfilled, (state, action) => {
      state.additionalOffers = action.payload;
    })
    .addCase(setCurrentBooking, (state, action) => {
      state.current = action.payload;
    })
    .addCase(resetCurrentBooking, (state) => {
      state.current = undefined;
      state.comments = [];
      state.selection = initialState.selection;
      state.additionalOffers = [];
    })
    .addCase(selectTripOffer, (state) => {
      state.current = undefined;
    })
    .addCase(getComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    })
    .addCase(getHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    })
    .addMatcher(
      (action) =>
        [getBooking.fulfilled.type, postBooking.fulfilled.type].includes(
          action.type
        ),
      (state, action) => {
        state.current = action.payload;
      }
    )
    .addMatcher(
      (action) =>
        [
          confirmRefund.fulfilled.type,
          confirmRelease.fulfilled.type,
          resetAdmissionSelection.type,
        ].includes(action.type),
      (state) => {
        state.selection = initialState.selection;
      }
    );
});
