import { Button } from '@fleet/shared';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { OfferAccommodationType, TripOffer } from 'dto/trip';
import { selectSelectedOffers } from 'features/trip/tripSelector';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { SeatPreferenceModal } from 'routes/tickets/searchResults/SeatPreferenceModal';
import { useSelector } from 'store/utils';

interface OfferCardProps extends TripOffer {
  onClick: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    offerCard: {
      border: `thin solid ${theme.palette.divider}`,
      padding: '8px 12px',
      cursor: 'pointer',
      '&:hover:not($offerCardSelected)': {
        background: theme.palette.primary.light,
        color: theme.palette.action.hoverText,
        '& $flexibility svg': {
          color: theme.palette.primary.main,
        },
      },
    },
    offerCardSelected: {
      background: theme.palette.secondary.main,
      '&, & $info svg': {
        color: 'white!important',
      },
    },
    preferenceBtn: {
      width: '100%',
      height: '1.5rem',
      fontSize: '0.75rem',
      '&, &:hover': {
        background: theme.palette.common.white,
      },
    },
    info: {},
  }),
  { name: 'JourneyOffer' }
);

export const OfferCard: FC<OfferCardProps> = ({ onClick, ...offer }) => {
  const {
    admissions,
    exchangeable,
    refundable,
    productDescription,
    productSummary,
    flexibility,
    reservationLegCoverage,
  } = offer;
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);
  const classes = useStyles();
  const selectedOffersInfo = useSelector(selectSelectedOffers);
  const isSelected = find(selectedOffersInfo.trips, offer);
  const isExchangeable = exchangeable !== 'NO';
  const isRefundable = refundable !== 'NO';
  const productDescriptiveTexts = useMemo(
    () =>
      uniq(
        admissions.reduce<Array<string>>(
          (texts, { productDescriptiveTexts }) => [
            ...texts,
            ...(productDescriptiveTexts?.map(
              ({ description }) => description
            ) ?? []),
          ],
          []
        )
      ),
    [admissions]
  );

  const availableOffersQty = useMemo(() => {
    return reservationLegCoverage
      .filter(({ placeProperties }) => !!placeProperties)
      .reduce((prev, curr) => curr.numericAvailability + prev, 0);
  }, [reservationLegCoverage]);

  const showOfferSeatPreference = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isSelected) {
        onClick();
      }

      setTimeout(() => setShowPreferenceModal(true), 100);
    },
    [isSelected, onClick]
  );

  return (
    <>
      <Grid item xs={3} xl={2}>
        <Card
          onClick={onClick}
          className={classNames(classes.offerCard, {
            [classes.offerCardSelected]: find(selectedOffersInfo.trips, offer),
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
            mb={0.5}
          >
            {!!productDescriptiveTexts.length && (
              <Tooltip
                content={
                  <Typography whiteSpace="break-spaces">
                    {productDescriptiveTexts.join('\n')}
                  </Typography>
                }
              >
                <Icon name="info-circle" color="warning" />
              </Tooltip>
            )}
            <Typography variant="body1" fontWeight="bold" flex={1}>
              {productDescription || productSummary}
            </Typography>
            {!!availableOffersQty && (
              <Typography variant="body2">
                <TransSubtitle
                  i18nKey="availableNr"
                  values={{ count: availableOffersQty }}
                />
              </Typography>
            )}
          </Stack>
          <Stack direction="row" spacing={1} mb={0.5}>
            <Typography variant="body1" noWrap>
              {`${offer.price.amount} ${offer.price.currency}`}
            </Typography>
            <Stack spacing={0.5}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Icon
                  name={isExchangeable ? 'check-circle' : 'deactivate'}
                  size={isExchangeable ? 16 : 14}
                  color={isExchangeable ? 'success' : 'error'}
                />
                <Typography variant="body2">
                  <TransSubtitle
                    i18nKey={isExchangeable ? 'changeable' : 'nonChangeable'}
                  />
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Icon
                  name={isRefundable ? 'check-circle' : 'deactivate'}
                  size={isRefundable ? 16 : 14}
                  color={isRefundable ? 'success' : 'error'}
                />
                <Typography variant="body2">
                  <TransSubtitle
                    i18nKey={isRefundable ? 'refundable' : 'nonRefundable'}
                  />
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Icon name="ticket-change" />
              <Typography variant="body2">
                <TransLabel i18nKey={flexibility} />
              </Typography>
            </Stack>
          </Stack>
          {offer.offerAccommodationType === OfferAccommodationType.SEAT && (
            <Button
              variant="outlined"
              startIcon={<Icon name="seat" />}
              className={classes.preferenceBtn}
              onClick={showOfferSeatPreference}
              disabled={!offer.reservationLegCoverage?.length}
            >
              <TransButton i18nKey="seatPreference" />
            </Button>
          )}
        </Card>
      </Grid>
      {showPreferenceModal && (
        <SeatPreferenceModal
          offer={offer}
          onClose={() => setShowPreferenceModal(false)}
        />
      )}
    </>
  );
};
