import {
  BookingAdmission,
  BookingDetails,
  BookingDetailsPassenger,
} from 'dto/booking';
import { FormApi } from 'final-form';
import {
  PassengerDetailsPayload,
  payByLink,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { TripLeg } from 'dto/trip';
import {
  sendConfirmation,
  setCurrentBooking,
  triggerBookingFulfillment,
} from 'features/booking/bookingActions';
import { useDispatch } from 'store/utils';
import { TicketSelectionPayload } from 'components/SendTicketsSelection';

export const prefillPayerFormData = (
  passengerId: string,
  passengers: BookingDetailsPassenger[],
  setShowAreaCodeInput: Dispatch<SetStateAction<boolean>>,
  form: FormApi<PassengerDetailsPayload, Partial<PassengerDetailsPayload>>
) => {
  const passenger = passengers.find(({ id }) => id === passengerId);
  if (passenger) {
    const { firstName, lastName, contactInformation } = passenger;
    setShowAreaCodeInput(!contactInformation.phoneNumber.value);
    form.reset({
      firstName: firstName.value,
      lastName: lastName.value,
      email: contactInformation.emailAddress.value,
      phone: {
        number: contactInformation.phoneNumber.value,
      },
    });
  } else {
    setShowAreaCodeInput(true);
    form.reset({});
  }
};

export const usePurchaserEmail = (
  payerDetailsForm: FormApi<
    PassengerDetailsPayload,
    Partial<PassengerDetailsPayload>
  >,
  values: PassengerDetailsPayload
) => {
  return useMemo(() => {
    if (payerDetailsForm.getFieldState('email')?.valid) {
      return values.email;
    }
  }, [payerDetailsForm, values]);
};

export const useShowValidations = (
  form: FormApi<PassengerDetailsPayload, Partial<PassengerDetailsPayload>>,
  invalid: boolean
) => {
  return useCallback(() => {
    if (invalid) form.submit();
  }, [form, invalid]);
};

export const prepareTrips = (booking?: BookingDetails) => {
  if (!booking) return [];
  const tripsByJourneyRef = groupBy(booking.bookedTrips, 'journeyRef');
  const journeys = Object.keys(tripsByJourneyRef);
  return journeys.map((ref) => {
    const journeyTrips = tripsByJourneyRef[ref];
    const [trip] = journeyTrips;
    const legs = journeyTrips
      .reduce<Array<TripLeg>>((legs, trip) => [...legs, ...trip.legs], [])
      .sort(
        (a, b) =>
          new Date(a.departureTime).valueOf() -
          new Date(b.departureTime).valueOf()
      );
    return {
      ...trip,
      bookedOffer: {
        ...trip.bookedOffer,
        admissions: journeyTrips.reduce<Array<BookingAdmission>>(
          (acc, trip) => [...acc, ...trip.bookedOffer.admissions],
          []
        ),
      },
      legs,
      arrivalTime: journeyTrips[journeyTrips.length - 1].arrivalTime,
      destinationStop: journeyTrips[journeyTrips.length - 1].destinationStop,
    };
  });
};

export const useConfirmationSend = (bookingId: string) => {
  const dispatch = useDispatch();
  return useCallback(
    async ({
      confirmationRecipient = [],
      additionalConfirmationRecipients = [],
      passengerSelection = [],
      includeTickets,
    }) => {
      const confirmationRecipients = [
        ...confirmationRecipient,
        ...additionalConfirmationRecipients,
      ];
      await Promise.all([
        ...(confirmationRecipients.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'purchase-confirmation',
                  bookingId,
                  includeTickets,
                  emailsOverride: confirmationRecipients,
                })
              ),
            ]
          : []),
        ...(passengerSelection.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'ticket-delivery',
                  bookingId,
                  passengers: passengerSelection.map((passengerId: string) => ({
                    passengerId,
                  })),
                })
              ),
            ]
          : []),
      ]);
    },
    [bookingId, dispatch]
  );
};

export const useSubmitBooking = (
  bookingId: string,
  emailsFormInvalid: boolean,
  emailSend: boolean,
  emailsForm: FormApi<TicketSelectionPayload>,
  paymentMethod: string,
  showSuccessPage: () => void
) => {
  const dispatch = useDispatch();

  return useCallback(
    async ({ phone, ...values }) => {
      const phoneNumber = [phone?.areaCode, phone?.number]
        .filter(Boolean)
        .join('');
      if (emailsFormInvalid) return emailsForm.submit();

      await dispatch(
        updatePurchaserDetails({
          bookingId,
          ...(phoneNumber && { phone: { number: phoneNumber } }),
          ...values,
        })
      ).unwrap();
      let updatedBooking;

      if (paymentMethod === 'payByLink') {
        await dispatch(
          payByLink({
            bookingId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber,
          })
        );
      } else {
        updatedBooking = await dispatch(
          triggerBookingFulfillment(bookingId)
        ).unwrap();
        showSuccessPage();
      }
      emailSend && (await emailsForm.submit());
      updatedBooking && dispatch(setCurrentBooking(updatedBooking));
    },
    [
      emailsFormInvalid,
      emailsForm,
      dispatch,
      bookingId,
      paymentMethod,
      emailSend,
      showSuccessPage,
    ]
  );
};
