import { Table, TableColumns } from '@fleet/shared';
import { formatDate, currentDateTimeFormat } from '@fleet/shared/utils/date';
import { FulfillmentStatus, HistoryEvent, RefundReason } from 'dto/booking';
import { bookingHistorySelector } from 'features/booking/bookingSelectors';
import { TransField } from 'i18n/trans/field';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useTable } from 'react-table';
import { useSelector } from 'store/utils';
import { replaceUnicodeChars } from 'utils/common';

interface HistoryProps {}

export const History: FC<HistoryProps> = () => {
  const history = useSelector(bookingHistorySelector);
  const columns = useMemo<TableColumns<HistoryEvent>>(
    () => [
      {
        accessor: 'timestamp',
        Cell: ({ value }) => formatDate(value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
      },
      {
        id: 'change',
        accessor: ({ change }) => <TransField i18nKey={change} />,
        Header: <TransTableHead i18nKey="action" />,
      },
      {
        accessor: 'affectedPerson',
        Header: <TransTableHead i18nKey="person" />,
        Cell: ({ value }) => value || '-',
      },
      {
        accessor: 'summary',
        Header: <TransTableHead i18nKey="summary" />,
        width: '20rem',
      },
      {
        accessor: 'user',
        Header: <TransTableHead i18nKey="user" />,
      },
      {
        id: 'changeReason',
        accessor: ({ change, changeReason }) => {
          const translation =
            changeReason ||
            (change === FulfillmentStatus.REFUNDED && RefundReason.CLIENT_WISH);
          return translation
            ? replaceUnicodeChars(
                renderToString(<TransField i18nKey={translation} />)
              )
            : '-';
        },
        Header: <TransTableHead i18nKey="changeReason" />,
      },
      {
        accessor: 'paymentMethod',
        Header: <TransTableHead i18nKey="paymentMethod" />,
        Cell: ({ value }) => value || '-',
      },
      {
        accessor: 'amount',
        Header: <TransTableHead i18nKey="amount" />,
        Cell: ({ value }) => (value.split(' ')[0] === '0' ? ' - ' : value),
      },
    ],
    []
  );

  const table = useTable({
    data: history,
    columns,
  });
  return <Table table={table} />;
};
