import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  bookingFeesSelector,
  bookingTotalFeesSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransLabel } from 'i18n/trans/label';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { Button, Icon, useModal } from '@fleet/shared';
import { ConfirmModal } from 'components/ConfirmModal';
import { TransParagraph } from 'i18n/trans/paragraph';
import {
  getBooking,
  removeFeeFromBooking,
} from 'features/booking/bookingActions';

interface BookingTotalFeeProps {
  isOverview?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: '0.25rem',
      padding: '0.5rem 0',
      gap: '2rem',
    },
    borders: {
      border: `thin solid ${theme.palette.divider}`,
      padding: '4px 16px',
    },
    removeBtn: {
      minWidth: 0,
      padding: 0,
      '& .MuiButton-startIcon': {
        color: theme.palette.error.main,
      },
    },
  }),
  { name: 'BookingTotalFee' }
);
export const BookingTotalFee: FC<BookingTotalFeeProps> = ({ isOverview }) => {
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const { open, onClose, onOpen } = useModal();
  const classes = useStyles();
  const groupedBookingFees = useSelector(bookingTotalFeesSelector);
  const bookingFees = useSelector(bookingFeesSelector);
  const totalFeeAmount = groupedBookingFees.reduce(
    (total, fee) => total + fee.price.amount,
    0
  );
  const totalFeeString =
    !!totalFeeAmount &&
    [totalFeeAmount, groupedBookingFees[0].price.currency].join(' ');

  const onRemoveBookingFee = useCallback(async () => {
    onClose();
    await dispatch(
      removeFeeFromBooking(bookingFees.map((fee) => fee.id))
    ).unwrap();
    await dispatch(getBooking(booking!.id));
  }, [booking, bookingFees, dispatch, onClose]);

  if (!totalFeeString) return null;

  return (
    <Stack
      className={classNames(classes.root, { [classes.borders]: isOverview })}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1" fontWeight="bold">
        <TransLabel i18nKey="bookingFee" />
      </Typography>
      <Box flexGrow={1} />
      <Typography flexGrow={1} variant="h2" textAlign="right">
        {totalFeeString}
      </Typography>
      {isOverview && (
        <>
          <Button
            className={classes.removeBtn}
            variant="text"
            startIcon={<Icon name="trash" />}
            onClick={onOpen}
          />
          <ConfirmModal
            messageBody={<TransParagraph i18nKey={'removeBookingFee'} />}
            onConfirm={onRemoveBookingFee}
            onClose={onClose}
            isOpen={open}
          />
        </>
      )}
    </Stack>
  );
};
