import {
  Button,
  currentDateTimeFormat,
  FormProvider,
  Icon,
  Modal,
  SelectField,
  useForm,
} from '@fleet/shared';
import { formatDate } from '@fleet/shared/utils/date';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PriceWithFee } from 'components/PriceWithFee';
import { AdditionalOffer } from 'dto/booking';
import { TripLeg } from 'dto/trip';
import {
  addAdditionalOfferToBooking,
  getBooking,
} from 'features/booking/bookingActions';
import {
  bookingAdditionalOffersSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingAddonsLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import keyBy from 'lodash/keyBy';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';

interface AncillaryModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    content: {
      width: 352,
    },
    typeSelection: {
      padding: '1rem',
      background: theme.palette.background.default,
    },
  }),
  { name: 'AncillaryModal' }
);

const FORM_ID = 'ancillary-form';

export const AncillaryModal: FC<AncillaryModalProps> = ({ open, onClose }) => {
  const booking = useSelector(currentBookingSelector)!;
  const { id, bookedTrips, passengers, externalReference } = booking;
  const classes = useStyles();
  const dispatch = useDispatch();
  const additionalOffers = useSelector(bookingAdditionalOffersSelector);
  const loading = useSelector(bookingAddonsLoadingSelector);
  const journeyOptions = useMemo(() => {
    const journeyTripsMap = keyBy(booking.bookedTrips, 'journeyRef');
    return Object.keys(journeyTripsMap).map((ref) => {
      const { originStop, departureTime, arrivalTime, destinationStop } =
        journeyTripsMap[ref];
      return {
        label: `${originStop.name} - ${destinationStop.name} (${[
          departureTime,
          arrivalTime,
        ]
          .map((date) => formatDate(date, currentDateTimeFormat))
          .join(' - ')})`,
        value: journeyTripsMap[ref].bookedOffer.id,
        passengerIds: journeyTripsMap[ref].bookedOffer.admissions.reduce<
          Array<string>
        >((acc, admission) => [...acc, ...(admission.passengerIds ?? [])], []),
      };
    });
  }, [booking.bookedTrips]);
  const additionalOffersMap = useMemo(
    () => additionalOffers && keyBy(additionalOffers, 'additionalOfferId'),
    [additionalOffers]
  );
  const onSubmit = useCallback(
    async ({ offerId, legId, passengerRefs }) => {
      const selectedAddon = additionalOffersMap?.[offerId];
      if (!selectedAddon) return;
      await dispatch(
        addAdditionalOfferToBooking({
          bookedOfferId: selectedAddon.bookedOfferId,
          offerId,
          ancillaryId: selectedAddon.ancillaryOfferParts[0].id,
          passengerRefs,
          tripCoverage: {
            tripId: selectedAddon.ancillaryOfferParts[0].tripId,
            journeyReference: externalReference,
            coveredLegIds: [legId],
          },
        })
      ).unwrap();
      onClose();
      await dispatch(getBooking(id)).unwrap();
    },
    [additionalOffersMap, dispatch, externalReference, id, onClose]
  );
  const { form, handleSubmit, values } = useForm({
    onSubmit,
    initialValues: {
      bookedOfferId:
        journeyOptions.length === 1 ? journeyOptions[0].value : undefined,
    },
    subscription: { values: true },
  });

  useEffect(() => {
    !open && form.reset();
  }, [form, open]);

  useEffect(() => {
    form.batch(() => {
      form.change('offerId', undefined);
      form.change('legId', undefined);
      form.change('passengerRefs', undefined);
    });
  }, [form, values.bookedOfferId]);

  const legOptions = useMemo(() => {
    if (!values.bookedOfferId) return [];

    return bookedTrips
      .filter(({ bookedOffer }) => bookedOffer.id === values.bookedOfferId)
      .reduce<Array<TripLeg>>((legs, trip) => [...legs, ...trip.legs], [])
      .map(
        ({ arrivalTime, departureTime, originStop, destinationStop, id }) => ({
          label: [
            `${formatDate(departureTime)} ${originStop.name}`,
            `${formatDate(arrivalTime)} ${destinationStop.name}`,
          ].join(' -\n'),
          value: id,
        })
      );
  }, [bookedTrips, values.bookedOfferId]);

  const ancillariesOptions = useMemo(() => {
    if (!values.legId || !additionalOffers?.length) return [];
    return additionalOffers
      .filter(({ ancillaryOfferParts }) =>
        ancillaryOfferParts.find(({ legIds }) => legIds.includes(values.legId))
      )
      .map(({ ancillaryOfferParts, additionalOfferId }) => ({
        label: ancillaryOfferParts[0]?.products?.[0].description,
        value: additionalOfferId,
      }));
  }, [additionalOffers, values.legId]);

  const selectedOffer = useMemo<AdditionalOffer>(
    () => values.offerId && additionalOffersMap?.[values.offerId],
    [additionalOffersMap, values.offerId]
  );

  const passengerOptions = useMemo(() => {
    if (!values.bookedOfferId) return [];
    const availablePassengerIds = journeyOptions.find(
      ({ value }) => value === values.bookedOfferId
    )?.passengerIds;
    return passengers
      .filter(({ id }) => availablePassengerIds?.includes(id))
      .map(({ firstName, lastName, externalReference }) => ({
        label: `${firstName.value} ${lastName.value}`,
        value: externalReference,
      }));
  }, [journeyOptions, passengers, values.bookedOfferId]);

  return (
    <Modal
      title={<TransButton i18nKey="addAddon" />}
      open={open}
      onClose={onClose}
      actionButton={
        <Button
          startIcon={<Icon name="plus" />}
          variant="contained"
          loading={loading}
          type="submit"
          form={FORM_ID}
        >
          <TransButton i18nKey="addAddon" />
        </Button>
      }
    >
      <FormProvider {...form}>
        <Stack
          spacing={3}
          id={FORM_ID}
          component="form"
          onSubmit={handleSubmit}
          className={classes.content}
        >
          {journeyOptions.length > 1 && (
            <SelectField
              name="bookedOfferId"
              label={<TransField i18nKey="selectJourney" />}
              options={journeyOptions}
              disabled={loading}
            />
          )}
          <SelectField
            name="legId"
            label={<TransField i18nKey="selectLeg" />}
            disabled={loading || !values.bookedOfferId}
            options={legOptions}
          />
          <SelectField
            name="offerId"
            label={<TransField i18nKey="selectAddon" />}
            options={ancillariesOptions}
            disabled={loading || !values.legId}
          />
          <SelectField
            name="passengerRefs"
            label={<TransField i18nKey="selectPassenger" />}
            options={passengerOptions}
            disabled={loading}
            multiple
          />
          <Stack spacing={1}>
            <Typography variant="body2">
              <TransSubtitle i18nKey="addonPrice" />
            </Typography>
            <Typography variant="body2">
              {selectedOffer ? (
                <PriceWithFee
                  {...selectedOffer.ancillaryOfferParts?.[0].price}
                />
              ) : (
                ' - '
              )}
            </Typography>
          </Stack>
        </Stack>
      </FormProvider>
    </Modal>
  );
};
