import { Stack, Typography } from '@mui/material';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { useSelector } from 'store/utils';
import { prepareTrips } from 'utils/overview';

const useStyles = makeStyles(() => ({
  icon: {
    width: '34px',
    flexShrink: 0,
  },
}));

export const JourneySummaries = () => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const trips = useMemo(() => prepareTrips(booking), [booking]);
  const [origin, destination] = useMemo(
    () => (isEmpty(trips) ? [] : [trips[0], trips[trips.length - 1]]),
    [trips]
  );

  if (!origin || !destination) return null;

  return (
    <Stack spacing={1}>
      {trips.map(
        ({
          id,
          alliances,
          originStop,
          departureTime,
          destinationStop,
          arrivalTime,
        }) => (
          <Stack key={id} direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">
              {[departureTime, arrivalTime]
                .map((date) => formatDate(date, currentDateTimeFormat))
                .join(' - ')}
            </Typography>
            <DaysAfterCount startDate={departureTime} endDate={arrivalTime} />
            <Icon className={classes.icon} name="journey-changeover" />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {[originStop.name, destinationStop.name].join(' - ')}
            </Typography>
            {alliances.includes('RESPLUS') && (
              <Icon name="resplus-horizontal" height={15} width={53} />
            )}
          </Stack>
        )
      )}
    </Stack>
  );
};
