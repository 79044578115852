import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon, Loadable, Modal } from '@fleet/shared';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import {
  prepareTrips,
  useConfirmationSend,
  usePurchaserEmail,
  useShowValidations,
  useSubmitBooking,
} from 'utils/overview';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { PayerDetailsForm } from 'components/PayerDetailsForm';
import { useForm } from '@fleet/shared/form';
import { PassengerDetailsPayload } from 'features/trip/tripActions';
import { TicketSelectionPayload } from 'components/SendTicketsSelection';
import { TicketFulfillment } from 'components/TicketFulfillment';
import { PaymentMethods } from 'components/PaymentMethods';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { Divider, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { getBooking } from 'features/booking/bookingActions';
import { PayByLinkModal } from 'routes/tickets/checkout/PayByLinkModal';
import { paymentStatusSelector } from 'features/trip/tripSelector';
import { noop } from '@fleet/shared/utils/noop';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: '900px',
    },
    '&.MuiDialogActions-root': {
      boxShadow: '0px 20px 32px 0px rgba(0, 0, 0, 0.5)',
    },
  }),
  { name: 'CheckoutOverviewModal' }
);

interface CheckoutOverviewModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const CheckoutOverviewModal: FC<CheckoutOverviewModalProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const classes = useStyles();
  const paymentStatus = useSelector(paymentStatusSelector);
  const [emailSend, setEmailSend] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const booking = useSelector(currentBookingSelector)!;
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const preparedTrips = useMemo(() => prepareTrips(booking), [booking]);
  const totalToPay = useMemo(() => {
    if (booking?.provisionalPrice) {
      return `${booking.provisionalPrice.amount} ${booking.provisionalPrice.currency}`;
    }
  }, [booking]);
  const handleConfirmationSend = useConfirmationSend(booking.id);

  const { form: emailsForm, invalid: emailsFormInvalid } =
    useForm<TicketSelectionPayload>({
      onSubmit: handleConfirmationSend,
      subscription: { invalid: true },
    });

  const onSuccess = useCallback(() => {
    emailSend && emailsForm.submit();
    dispatch(getBooking(booking.id));
    alert.success(
      <TransAlert i18nKey="bookingIsPaid" values={{ id: booking.id }} />
    );
    onClose();
  }, [booking.id, dispatch, emailSend, emailsForm, alert, onClose]);

  useEffect(() => {
    emailsForm.reset();
  }, [emailsForm, emailSend]);

  const onSubmit = useSubmitBooking(
    booking.id,
    emailsFormInvalid,
    emailSend,
    emailsForm,
    paymentMethod,
    onSuccess
  );

  const { form, handleSubmit, values, invalid } =
    useForm<PassengerDetailsPayload>({
      subscription: { values: true, invalid: true },
      onSubmit,
    });

  const handleOnClose = useCallback(() => {
    setPaymentMethod('');
    form.restart();
    emailsForm.restart();
    onClose();
  }, [onClose, emailsForm, form]);

  const purchaserEmail = usePurchaserEmail(form, values);
  const showValidations = useShowValidations(form, invalid);

  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="paySelectedAdmissions" />}
      open={isOpen}
      onClose={handleOnClose}
      actionButton={
        <>
          <Button
            variant="text"
            onClick={() => form.restart()}
            label={<TransButton i18nKey="resetFields" />}
            disabled={isBookingExpired}
          />
          <Button
            variant="contained"
            form={'purchaserDetails'}
            type="submit"
            disabled={isBookingExpired || !paymentMethod}
            startIcon={<Icon name={'check'} />}
          >
            <TransButton i18nKey="confirmTransaction" />
          </Button>
        </>
      }
    >
      <Loadable loading={loading}>
        <Stack spacing={2} divider={<Divider />}>
          {preparedTrips.map((trip, idx) => (
            <JourneyOverview
              isOverview
              trip={trip}
              key={trip.id}
              canDelete={!!idx}
            />
          ))}
          <PayerDetailsForm form={form} handleSubmit={handleSubmit} />
          <TicketFulfillment
            emailSend={emailSend}
            invalid={invalid}
            setEmailSend={setEmailSend}
            purchaserEmail={purchaserEmail}
            showValidations={showValidations}
            emailsForm={emailsForm}
          />
          <PaymentMethods
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
          {!!paymentStatus && <PayByLinkModal onPaymentSuccess={() => noop} />}
          <Stack direction="row" alignItems={'center'}>
            <Icon name={'cart'} size={'24px'} />
            <Typography variant="h2" sx={{ mr: '8px' }}>
              <TransField i18nKey={'total'} />:
            </Typography>
            <Typography variant="h2">{totalToPay}</Typography>
          </Stack>
        </Stack>
      </Loadable>
    </Modal>
  );
};
