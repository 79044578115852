import { selector } from 'store/utils';

export const cardsSelector = selector((state) => state.classification.CARD);
export const classificationsSelector = selector(
  (state) => state.classification
);

export const stopsSelector = selector((state) => state.classification.STOP);
export const selectPtModes = selector((state) => state.classification.PT_MODE);
export const selectAreaCodeOptions = selector((state) =>
  state.classification.COUNTRY.filter(({ areaCode }) => areaCode).map(
    ({ areaCode, areaCodeDisplayName }) => ({
      label: areaCodeDisplayName,
      value: areaCode!,
    })
  )
);

export const elementsSelector = selector(
  (state) => state.classification.ELEMENTS
);

export const carriersSelector = selector(
  (state) => state.classification.CARRIER
);
