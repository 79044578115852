import { Table } from '@fleet/shared/components/Table';
import {
  Column,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FC, useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Stack, Divider, Box } from '@mui/material';
import { Condition, TravelPass } from 'dto/trip';
import { useDispatch, useSelector } from 'store/utils';
import {
  selectSelectedOffers,
  travelPassesSelector,
} from 'features/trip/tripSelector';
import classNames from 'classnames';
import { selectTravelPassOffer } from 'features/trip/tripActions';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { Dropdown, Tooltip } from '@fleet/shared';
import { renderToString } from 'react-dom/server';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { searchLoadingSelector } from 'features/loading/loadingSelectors';
import { EmptyResults } from 'components/EmptyResults';

const useStyles = makeStyles(
  (theme) => ({
    header: {
      padding: '1rem 2rem',
    },
    hidden: {
      display: 'none',
    },
    table: {
      '& table': {
        overflow: 'hidden',
      },
    },
    cell: {
      cursor: 'pointer',
      borderBottom: `thin solid ${theme.palette.divider}`,
      '&:first-of-type > div': {
        whiteSpace: 'nowrap',
        paddingLeft: '1.875rem!important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:last-of-type > div': {
        whiteSpace: 'nowrap',
        paddingRight: '1.875rem!important',
      },
    },
    cellExpanded: {
      background: 'initial',
      color: 'initial',
    },
    selectBtn: {
      display: 'inline-block',
      marginLeft: 'auto',
      padding: '0.5rem 0.75rem',
      margin: '0.5rem 0',
      border: `thin solid ${theme.palette.divider}`,
      borderRadius: '0.25rem',
      '&$selected': {
        background: theme.palette.secondary.main,
        '&, svg': {
          color: 'white!important',
        },
      },
    },
    selected: {},
    sortDropdown: {
      '& .MuiButton-endIcon > .Icon-root': {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
  }),
  { name: 'TravelPassesTable' }
);

interface TravelPassesTableProps {}

export const TravelPassesTable: FC<TravelPassesTableProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(searchLoadingSelector);
  const travelPassesResults = useSelector(travelPassesSelector);
  const emptyResults = useMemo(
    () => !loading && !travelPassesResults.length,
    [loading, travelPassesResults]
  );
  const sortOptions = useMemo(
    () =>
      [
        {
          label: <TransButton i18nKey="sortAsc" />,
          value: {
            id: 'productDescription',
            desc: false,
          },
        },
        {
          label: <TransButton i18nKey="sortDesc" />,
          value: {
            id: 'productDescription',
            desc: true,
          },
        },
        {
          label: <TransButton i18nKey="sortPriceAsc" />,
          value: {
            id: 'priceAmount',
            desc: false,
          },
        },
        {
          label: <TransButton i18nKey="sortPriceDesc" />,
          value: {
            id: 'priceAmount',
            desc: true,
          },
        },
      ] as const,
    []
  );

  const { travelPass } = useSelector(selectSelectedOffers);
  const getOfferClickHandler = useCallback(
    (offer: TravelPass) => (e: React.MouseEvent<HTMLParagraphElement>) => {
      e.stopPropagation();
      dispatch(
        selectTravelPassOffer(offer.id === travelPass?.id ? undefined : offer)
      );
    },
    [dispatch, travelPass]
  );
  const columns = useMemo<Column<TravelPass>[]>(
    () => [
      {
        id: 'description',
        accessor: ({ admissionOfferParts }) => {
          const description = admissionOfferParts[0].productDescription;
          return (
            <Tooltip content={description}>
              <Typography variant="subtitle">{description}</Typography>
            </Tooltip>
          );
        },
      },
      {
        id: 'summary',
        accessor: ({ summary, admissionOfferParts }) => (
          <Typography variant="body2" px={1}>
            {[
              summary,
              ...uniq(
                admissionOfferParts
                  .reduce<Array<Condition>>(
                    (conditions, admission) => [
                      ...conditions,
                      ...admission.conditions,
                    ],
                    []
                  )
                  .map(({ description }) => description)
              ),
            ].join(', ')}
          </Typography>
        ),
      },
      {
        id: 'price',
        accessor: (row) => (
          <Typography
            className={classNames(classes.selectBtn, {
              [classes.selected]: travelPass?.id === row.id,
            })}
            onClick={getOfferClickHandler(row)}
          >
            {[
              row.offerSummary.minimalPrice.amount,
              row.offerSummary.minimalPrice.currency,
            ].join(' ')}
          </Typography>
        ),
      },
      {
        id: 'priceAmount',
        accessor: (row) => row.offerSummary.minimalPrice.amount,
      },
    ],
    [classes.selectBtn, classes.selected, getOfferClickHandler, travelPass?.id]
  );

  const table = useTable<TravelPass>(
    {
      data: travelPassesResults,
      columns,
      initialState: {
        sortBy: [sortOptions[0].value],
        hiddenColumns: ['priceAmount'],
      },
    },
    useSortBy,
    useExpanded,
    usePagination
  );
  const activeSortTitleString = useMemo(() => {
    const [activeSort] = table.state.sortBy;
    return renderToString(find(sortOptions, { value: activeSort })!.label);
  }, [table.state.sortBy, sortOptions]);

  if (emptyResults)
    return (
      <>
        <Box className={classes.header}>
          <Typography variant="h1">
            <TransTitle i18nKey="searchResults" />
          </Typography>
        </Box>
        <Divider />
        <EmptyResults />
      </>
    );

  return (
    <>
      <Box className={classes.header}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h1">
            <TransTitle i18nKey="searchResults" />
          </Typography>
          <Dropdown
            className={classes.sortDropdown}
            label={
              <TransButton
                i18nKey="sortBy"
                values={{ sort: activeSortTitleString }}
              />
            }
            options={sortOptions.map(({ label, value }) => ({
              label,
              onClick: () => table.setSortBy([value]),
            }))}
          />
        </Stack>
      </Box>
      <Divider />
      <Table
        table={table}
        classes={{
          table: classes.table,
          thead: classes.hidden,
          cell: classes.cell,
          cellActive: classes.cellExpanded,
        }}
      />
    </>
  );
};
