import {
  api,
  Button,
  currentDateTimeFormat,
  formatDate,
  Modal,
  ReadOnlyField,
  Table,
  TableColumns,
  useModal,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { stringify } from 'qs';
import { FC, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import {
  TravelAccount,
  TravelPassBookingPart,
  TravelPassConsumption,
} from 'dto/booking';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import {
  carriersSelector,
  stopsSelector,
} from 'features/classification/classificationSelectors';
import { TransLabel } from 'i18n/trans/label';
import { useSelector } from 'store/utils';
import { validate } from 'uuid';

interface PassengerTravelPassProps {}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& label': {
        color: theme.palette.text.secondary,
      },
    },
    viewBtn: {
      padding: 0,
      textDecoration: 'underline',
    },
  }),
  {
    name: 'PassengerTravelPass',
  }
);

export const PassengerTravelPass: FC<PassengerTravelPassProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [travelAccount, setTravelAccount] = useState<TravelAccount>();
  const stops = useSelector(stopsSelector);
  const booking = useSelector(currentBookingSelector)!;
  const travelPass = (booking.bookingParts[0] as TravelPassBookingPart)
    .nonTripOffer;
  const isMultiRide = travelPass.productType === 'ADMISSION_MULTI_RIDE';
  const [fulfillment] = travelPass.fulfillments;
  const carriers = useSelector(carriersSelector);
  const carrierName = useMemo(() => {
    const carrierCode = fulfillment.issuer;
    const carrier = carriers.find(({ id }) => id === carrierCode);
    return carrier?.name;
  }, [carriers, fulfillment.issuer]);
  const tableData = useMemo(
    () => travelAccount?.consumptions ?? [],
    [travelAccount?.consumptions]
  );
  const nrOfUsages = useMemo(
    () =>
      isMultiRide && travelAccount
        ? (['remaining', 'total'] as const)
            .map((field) => travelAccount.balance[field])
            .join(' / ')
        : '',
    [isMultiRide, travelAccount]
  );
  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const columns = useMemo<TableColumns<TravelPassConsumption>>(
    () => [
      {
        accessor: 'consumedOn',
        Header: <TransTableHead i18nKey="usedOn" />,
        Cell: ({ value }) => formatDate(value, currentDateTimeFormat),
        width: '8.5rem',
      },
      {
        id: 'summary',
        accessor: ({ tripSummary: { origin, destination } }) =>
          [origin, destination]
            .map(
              ({ stopPlaceRef }) =>
                stops.find(({ id }) => stopPlaceRef === id)!.name
            )
            .join(' - '),
        Header: <TransTableHead i18nKey="journey" />,
        width: 'auto',
      },
      {
        id: 'startTime',
        accessor: ({ tripSummary: { startTime } }) =>
          formatDate(startTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureTime" />,
        width: '8rem',
      },
      {
        id: 'endTime',
        accessor: ({ tripSummary: { endTime } }) =>
          formatDate(endTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="arrivalTime" />,
        width: '8rem',
      },
      {
        id: 'bookingId',
        accessor: ({ bookingId }) => (
          <Link
            to={
              validate(bookingId)
                ? `/booking/${bookingId}`
                : `/bookings?reference=${bookingId}`
            }
          >
            {bookingId}
          </Link>
        ),
        Header: <TransTableHead i18nKey="bookingNr" />,
      },
    ],
    [stops]
  );
  const table = useTable({
    data: tableData,
    columns,
  });

  const getUsages = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = (
        await api.get<{ travelAccount: TravelAccount }>(
          `/travel-accounts${stringify(
            {
              issuer: fulfillment.issuer,
              travelAccount: fulfillment.controlNumber,
            },
            { addQueryPrefix: true }
          )}`
        )
      ).data.travelAccount;
      setTravelAccount(response);
      onOpen();
    } catch (e) {}
    setIsLoading(false);
    onOpen();
  }, [fulfillment.controlNumber, fulfillment.issuer, onOpen]);

  return (
    <>
      <Grid
        container
        columns={6}
        spacing={2}
        rowSpacing={1}
        classes={{
          root: classes.root,
        }}
      >
        <Grid item xs={1}>
          <ReadOnlyField
            value={travelPass.conditions
              .map(({ description }) => description)
              .join('\n')}
            label={<TransField i18nKey="conditions" />}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={1}>
          <ReadOnlyField
            value={fulfillment.controlNumber}
            label={<TransField i18nKey="travelPassNr" />}
          />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField
            value={travelPass.productCode}
            label={<TransField i18nKey="travelPassCode" />}
          />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField
            value={carrierName}
            label={<TransField i18nKey="carrier" />}
          />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField
            value={
              !travelPass.productType || (
                <TransLabel i18nKey={travelPass.productType} />
              )
            }
            label={<TransField i18nKey="type" />}
          />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField
            value={
              <Button
                variant="text"
                loading={isLoading}
                className={classes.viewBtn}
                onClick={getUsages}
                label={
                  <Typography variant="body2">
                    <TransButton i18nKey="viewUsage" />
                  </Typography>
                }
              />
            }
            label={<TransField i18nKey="nrOfUsages" />}
          />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField
            value={[travelPass.validFrom, travelPass.validUntil]
              .map((date) => formatDate(date))
              .join(' to ')}
            label={<TransField i18nKey="valid" />}
          />
        </Grid>
      </Grid>
      <Modal
        title={<TransTitle i18nKey="travelPassUsage" />}
        open={open}
        onClose={onClose}
        actionButton={<></>}
      >
        <Stack>
          {isMultiRide && (
            <ReadOnlyField
              value={nrOfUsages}
              label={<TransField i18nKey="nrOfUsages" />}
            />
          )}
          <Table table={table} />
        </Stack>
      </Modal>
    </>
  );
};
