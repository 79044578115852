import { api } from '@fleet/shared';
import { CompositionDirection } from '@fleet/widget/dto/composition';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { createAction } from '@reduxjs/toolkit';
import { AvailabilityPreferences, Warnings } from 'dto/booking';
import {
  Journey,
  JourneyLink,
  PassengerOfferSelection,
  PaymentStatus,
  TravelPass,
  TravelPassSearchParams,
  TripLeg,
  TripOffer,
  TripSearchParams,
} from 'dto/trip';
import { currentBookingIdSelector } from 'features/booking/bookingSelectors';
import { stringify } from 'qs';
import { createAsyncThunk } from 'store/utils';
import { runInSequence } from 'utils/common';

export const resetSearch = createAction('trip/resetSearch');

export const showTripStops = createAction<TripLeg | undefined>(
  'trip/showStops'
);

export const updateOfferSelection = createAction<{
  offerId?: string;
  selections: Array<PassengerOfferSelection>;
}>('trip/updateOfferSelection');

export const resetTripOffers = createAction<string>('trip/resetTripOffers');
export const setPdfDownload = createAction<boolean>('trip/setPdfDownload');

export const selectTripOffer = createAction<{
  reference: string;
  isOutbound: boolean;
  offers: Array<TripOffer>;
}>('trip/selectTripOffer');

export const selectTravelPassOffer = createAction<TravelPass | undefined>(
  'trip/selectTravelPassOffer'
);

export const unselectOffer = createAction<string>('trip/unselectOffer');
export const clearOfferSelection = createAction<string>(
  'trip/clearOfferSelection'
);

export const searchTrips = createAsyncThunk<
  {
    journeys: Array<Journey>;
    links: Array<JourneyLink>;
    nextAvailableDepartureDate?: string;
  },
  TripSearchParams
>('trip/search', async (payload) => {
  return (await api.post('/offers/search', payload)).data;
});

export const showTripsResultPage = createAsyncThunk<
  { journeys: Array<Journey>; links: Array<JourneyLink> },
  'next' | 'previous'
>('trip/showTripsResultPage', async (page, { getState }) => {
  const {
    trip: { links },
  } = getState();
  const resultsLink = links.find(({ rel }) => rel === page);
  return resultsLink ? (await api.get(resultsLink.href)).data : [];
});

export interface PassengerDetailsPayload {
  bookingId: string;
  passengerId: string;
  externalReference: string;
  firstName: string;
  lastName: string;
  gender?: string;
  birthDate?: string;
  email?: string;
  phone?: {
    areaCode?: string;
    number: string;
  };
}

export interface PurchaserDetailsPayload
  extends Omit<PassengerDetailsPayload, 'passengerId'> {
  address: {
    zipCode: string;
    streetName: string;
    city: string;
    countryName: string;
  };
  company: {
    name: string;
    registrationNumber: string;
    taxId: string;
  };
}

const preparePhonePayload = ({
  phone,
  ...rest
}: Partial<PassengerDetailsPayload | PurchaserDetailsPayload>) => ({
  ...rest,
  ...(phone?.number && {
    phone: {
      areaCode: '',
      number: phone.number,
    },
  }),
});
export const updatePassengersDetails = createAsyncThunk<
  void,
  Array<Partial<PassengerDetailsPayload>>
>('trip/updatePassengersDetails', async (passengers) => {
  await runInSequence(
    passengers.map(
      ({ bookingId, passengerId, ...payload }) =>
        () =>
          api.patch(
            `/bookings/${bookingId}/passengers/${passengerId}`,
            preparePhonePayload(payload)
          )
    )
  );
});

export const updatePurchaserDetails = createAsyncThunk<
  void,
  PurchaserDetailsPayload
>('trip/updatePurchaserDetails', async ({ bookingId, ...payload }) => {
  return await api.patch(
    `/bookings/${bookingId}/purchaser`,
    preparePhonePayload(payload)
  );
});

export const searchStops = createAsyncThunk<
  { stops: Array<{ reference: string; name: string }> },
  string
>('trip/searchStops', async (search: string) => {
  return (
    await api.get(
      `/places${stringify(
        { prefix: search, numberOfResults: 8 },
        { addQueryPrefix: true }
      )}`
    )
  ).data;
});

export const searchTravelPasses = createAsyncThunk<
  { nonTripOffers: Array<TravelPass> },
  TravelPassSearchParams
>('trip/searchTravelPasses', async (payload) => {
  return (await api.post('/nontrip-offers/search', payload)).data;
});

export const payByLink = createAsyncThunk<
  void,
  {
    bookingId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
  }
>('trip/payByLink', async ({ bookingId, ...payload }) => {
  return (
    await api.post(`/bookings/${bookingId}/pay/with/adyen/pay-by-link`, payload)
  ).data;
});

export const cancelPayments = createAsyncThunk<void, string | undefined>(
  'trip/cancelPayments',
  async (bookingId, { getState }) => {
    return (
      await api.post(
        `/bookings/${
          bookingId || currentBookingIdSelector(getState())
        }/payment-attempts/cancel`
      )
    ).data;
  }
);

export const getPaymentStatus = createAsyncThunk<
  PaymentStatus,
  string | undefined
>('trip/getPaymentStatus', async (bookingId, { getState }) => {
  return (
    await api.get<{ status: PaymentStatus }>(
      `/bookings/${
        bookingId || currentBookingIdSelector(getState())
      }/payment-status`
    )
  ).data.status;
});

export const clearPaymentStatus = createAction('trip/clearPaymentStatus');

export const getAvailabilitiesPreferences = createAsyncThunk<
  AvailabilityPreferences,
  { bookingId: string; bookedOfferId: string }
>(
  'trip/getAvailabilitiesPreferences',
  async ({ bookingId, bookedOfferId }, { getState }) => {
    return (
      await api.get(
        `/bookings/${
          bookingId || currentBookingIdSelector(getState())
        }/offerId/${bookedOfferId}/availabilities`
      )
    ).data;
  }
);

export const getAvailabilitiesPlaceMap = createAsyncThunk<
  Array<Vehicle & { direction: { id: CompositionDirection } }>,
  { bookingId: string; reservationId: string }
>(
  'trip/getAvailabilitiesPlaceMap',
  async ({ bookingId, reservationId }, { getState }) => {
    return (
      await api.get<{
        warnings: Warnings;
        placeMaps: Array<Vehicle & { direction: { id: CompositionDirection } }>;
      }>(
        `/bookings/${
          bookingId || currentBookingIdSelector(getState())
        }/reservations/${reservationId}/place-map`
      )
    ).data.placeMaps;
  }
);
