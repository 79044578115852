import { FieldArray, SelectOption, Theme, useFormContext } from '@fleet/shared';
import { CheckboxGroupField, SelectField, TextField } from '@fleet/shared/form';
import { useField } from '@fleet/shared/form/hooks/useField';
import { Icon, Switch } from '@fleet/shared/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConcessionForm } from 'components/searchBar/concession/ConcessionForm';
import { ClassificationGroup } from 'dto/classification';
import { CustomerCard, PassengerSpecification } from 'dto/trip';
import { cardsSelector } from 'features/classification/classificationSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'store/utils';
import { IS_IMS_AT } from 'utils/common';
import { v4 } from 'uuid';

interface PassengerCardProps {
  number: number;
  width: number;
  name: string;
  onRemove: () => void;
  totalPassengers: number;
}

const useStyles = makeStyles<Theme, PassengerCardProps>(
  (theme) => ({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      width: ({ width }) => `${width}px`,
      background: theme.palette.background.default,
      height: 'fit-content',
      '& .MuiCardContent-root': {
        padding: '1rem',
      },
    },
    card: {
      overflow: 'visible',
      width: '100%',
      position: 'relative',
      background: 'white',
      padding: '0.5rem',
      '& $deleteBtn': {
        padding: '0.25rem',
        background: theme.palette.error.main,
        color: 'white!important',
        position: 'absolute',
        top: -4,
        right: -8,
      },
    },
    accordion: {},
    deleteBtn: {},
    addBtn: {
      margin: 0,
      padding: 0,
    },
  }),
  {
    name: 'PassengerCard',
  }
);

enum PRM_NEEDS {
  WHEELCHAIR = 'WHEELCHAIR',
}

export const PassengerCard: FC<PassengerCardProps> = (props) => {
  const TRAVEL_PASS_TYPE = 'CARD_TYPE.TRAVEL_PASS';
  const form = useFormContext();
  const {
    input: {
      value: { prmNeeds, concession, ...restSpecification },
      onChange,
    },
  } = useField<string, PassengerSpecification>(props.name, form, {
    subscription: { value: true },
  });
  const cardState = form.getState().values.cardState;
  const { toggledCardMap, isApplyCardToAllPassengersToggleChecked } = cardState;
  const cards = useSelector(cardsSelector);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const { number, name, onRemove, totalPassengers } = props;
  const classes = useStyles(props);
  const [areDetailsShown, setDetailsShown] = useState(false);
  const checkIfCardIsRequired = useCallback(
    ({ code, type }) =>
      cards.find((card) => code === card.code && type === card.type.id)
        ?.cardIdRequired,
    [cards]
  );
  const cardsByType = useMemo(
    () => groupBy(cards, (card) => card.type.id),
    [cards]
  );
  const cardTypeOptions = useMemo(
    () => [
      ...Object.keys(cardsByType).map((type) => ({
        value: type,
        label: cardsByType[type][0].type.name,
      })),
      {
        value: TRAVEL_PASS_TYPE,
        label: renderToString(<TransField i18nKey="travelPass" />),
      },
    ],
    [cardsByType]
  );
  const cardCodeOptionsMap = useMemo<Record<string, Array<SelectOption>>>(
    () =>
      mapValues(cardsByType, (cards) =>
        cards
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map(({ code, name }) => ({
            label: name.text,
            value: code,
          }))
      ),
    [cardsByType]
  );
  const prmNeedsOptions = useMemo(
    () =>
      Object.values(PRM_NEEDS).map((key) => ({
        label: renderToString(<TransLabel i18nKey={key} />),
        value: key,
      })),
    []
  );
  const onPrmNeedsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...restSpecification,
        prmNeeds: e.target.checked ? [] : undefined,
      });
    },
    [onChange, restSpecification]
  );

  const onApplyCardToAllToggle = useCallback(
    (toggledCard: CustomerCard, isChecked: boolean, idxToCopy: number) => {
      form.batch(() => {
        const passengerSpecifications: PassengerSpecification[] =
          form.getFieldState('passengerSpecifications')?.value || [];

        const toggleCardUniqueId = toggledCard.uniqueCardId || v4();
        const newToggledCardsMap = { ...toggledCardMap };

        if (isChecked) {
          newToggledCardsMap[toggleCardUniqueId] = toggleCardUniqueId;
        } else {
          delete newToggledCardsMap[toggleCardUniqueId];
        }

        passengerSpecifications.forEach((specification, idx) => {
          if (idx === idxToCopy) return;

          const updatedSpecification = { ...specification };

          if (isChecked) {
            updatedSpecification.cards = [
              ...(updatedSpecification.cards || []),
              {
                ...toggledCard,
                number: undefined,
                uniqueCardId: toggleCardUniqueId,
              },
            ];
          } else {
            updatedSpecification.cards = updatedSpecification.cards?.filter(
              (card) => card.uniqueCardId !== toggleCardUniqueId
            );
          }
          form.change(`passengerSpecifications[${idx}]`, updatedSpecification);
        });

        form.change('cardState', {
          toggledCardMap: newToggledCardsMap,
          isApplyCardToAllPassengersToggleChecked: isChecked,
        });
      });
    },
    [form, toggledCardMap]
  );

  useEffect(() => {
    if (props.totalPassengers < 2 && isApplyCardToAllPassengersToggleChecked) {
      form.change('cardState', {
        toggledCardMap: {},
        isApplyCardToAllPassengersToggleChecked: false,
      });
    }
  }, [props.totalPassengers, isApplyCardToAllPassengersToggleChecked, form]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Stack spacing={1} alignItems="flex-start">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="body1" fontWeight="bold">
              <TransSubtitle i18nKey="passengerTitle" values={{ number }} />
            </Typography>
            <Button
              startIcon={<Icon name="trash" />}
              sx={{ p: 0 }}
              onClick={onRemove}
            >
              <TransButton i18nKey="delete" />
            </Button>
          </Stack>
          <TextField
            name={`${name}.age`}
            parse={(val: string) => val || undefined}
            label={<TransField i18nKey="passengerAge" />}
          />
          {!concession && (
            <FieldArray<CustomerCard> name={`${name}.cards`}>
              {({ fields }) => (
                <>
                  {fields.value?.map((card, idx) => (
                    <Card className={classes.card} key={idx} elevation={0}>
                      <Stack spacing={1}>
                        <SelectField
                          required
                          name={`${fields.name}[${idx}].type`}
                          options={cardTypeOptions}
                          defaultValue={cardTypeOptions[0]}
                          label={<TransField i18nKey="cardType" />}
                        />
                        {card.type === TRAVEL_PASS_TYPE ? (
                          <>
                            <SelectField
                              required
                              name={`${fields.name}[${idx}].issuer`}
                              options={carrierOptions}
                              label={<TransField i18nKey="issuer" />}
                            />
                            <TextField
                              name={`${fields.name}[${idx}].code`}
                              label={<TransField i18nKey="cardCode" />}
                              required
                            />
                            <TextField
                              key={card.code}
                              name={`${fields.name}[${idx}].number`}
                              label={<TransField i18nKey="cardNumber" />}
                              required
                            />
                            {number === 1 && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={1}
                              >
                                <Typography variant="body2">
                                  <TransLabel i18nKey="applyCardToAllPassengers" />
                                </Typography>
                                <Switch
                                  disabled={totalPassengers < 2 || !card.code}
                                  checked={
                                    isApplyCardToAllPassengersToggleChecked
                                  }
                                  onChange={(e) =>
                                    onApplyCardToAllToggle(
                                      card,
                                      e.target.checked,
                                      idx
                                    )
                                  }
                                />
                              </Stack>
                            )}
                          </>
                        ) : (
                          <>
                            <SelectField
                              required
                              name={`${fields.name}[${idx}].code`}
                              options={cardCodeOptionsMap[card.type]}
                              label={<TransField i18nKey="cardName" />}
                            />
                            <TextField
                              key={card.code}
                              name={`${fields.name}[${idx}].number`}
                              label={<TransField i18nKey="cardNumber" />}
                              required={checkIfCardIsRequired(card)}
                            />
                            {number === 1 && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={1}
                              >
                                <Typography variant="body2">
                                  <TransLabel i18nKey="applyCardToAllPassengers" />
                                </Typography>
                                <Switch
                                  disabled={totalPassengers < 2 || !card.code}
                                  checked={
                                    isApplyCardToAllPassengersToggleChecked
                                  }
                                  onChange={(e) =>
                                    onApplyCardToAllToggle(
                                      card,
                                      e.target.checked,
                                      idx
                                    )
                                  }
                                />
                              </Stack>
                            )}
                          </>
                        )}
                      </Stack>
                      <IconButton
                        className={classes.deleteBtn}
                        onClick={() => fields.remove(idx)}
                      >
                        <Icon name="close" />
                      </IconButton>
                    </Card>
                  ))}
                  <Button
                    startIcon={<Icon name="add" />}
                    sx={{ px: 0 }}
                    onClick={() =>
                      fields.push({
                        type: cardTypeOptions[0].value,
                      })
                    }
                  >
                    <TransButton i18nKey="addPassengerCard" />
                  </Button>
                </>
              )}
            </FieldArray>
          )}
          {!restSpecification.cards?.length && IS_IMS_AT && (
            <ConcessionForm name={`${name}.concession`} />
          )}
        </Stack>
      </CardContent>
      <Accordion
        className={classes.accordion}
        disableGutters
        elevation={1}
        expanded={areDetailsShown}
        onChange={() => setDetailsShown(!areDetailsShown)}
      >
        <AccordionSummary>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon name={`chevron-${areDetailsShown ? 'down' : 'up'}`} />
            <Typography variant="body1">
              <TransLabel i18nKey="details" />
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography variant="body1">
              <TransLabel i18nKey="prmNeed" />
            </Typography>
            <Switch defaultChecked={!!prmNeeds} onChange={onPrmNeedsChange} />
          </Stack>
          {!!prmNeeds && (
            <CheckboxGroupField
              name={`${name}.prmNeeds`}
              options={prmNeedsOptions}
              margin="normal"
              required
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
