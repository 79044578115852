import { resetSearch } from 'features/trip/tripActions';
import { TripsTable } from 'routes/tickets/searchResults/TripsTable';
import { useDispatch, useSelector } from 'store/utils';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { FC, useCallback, useContext, useMemo } from 'react';
import { TransButton } from 'i18n/trans/button';
import { Button } from '@fleet/shared/mui';
import { CartTotal } from 'components/CartTotal';
import {
  getAdditionalOffers,
  postBooking,
} from 'features/booking/bookingActions';
import { RouteChildrenProps } from 'react-router';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { alpha } from '@mui/material/styles';
import { getBookingDestinations } from 'utils/trip';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { useFormContext } from '@fleet/shared';
import { useField } from 'react-final-form-hooks';
import { PassengerSpecification } from 'dto/trip';
import { bookingLoadingSelector } from 'features/booking/bookingSelectors';
import { Card } from '@mui/material';
import { addOfferToBooking } from 'features/booking/bookingActions';

interface TripSearchResultsProps extends RouteChildrenProps {}

export const TripSearchResults: FC<TripSearchResultsProps> = ({ history }) => {
  const dispatch = useDispatch();
  const form = useFormContext();
  const {
    input: { value: passengerSpecifications },
  } = useField<Array<PassengerSpecification>>('passengerSpecifications', form);
  const tripResults = useSelector(tripsSelector);
  const postBookingLoading = useSelector(bookingLoadingSelector);
  const {
    trips: selectedOffers,
    reference: journeyReference,
    tripsSelectionMap,
  } = useSelector(selectSelectedOffers);
  const offersTotal = useMemo(() => {
    return selectedOffers.reduce(
      (total, offer) => ({
        amount: total.amount + offer.price.amount,
        currency: offer.price.currency,
      }),
      { amount: 0, currency: '' }
    );
  }, [selectedOffers]);
  const offersSelectionIncomplete = useMemo(() => {
    const coveredLegIds = selectedOffers.reduce<string[]>(
      (ids, { coveredLegIds }) => uniq([...ids, ...coveredLegIds]),
      []
    );
    const currentTripLegsIds = tripResults
      .find(({ reference }) => journeyReference === reference)
      ?.trips.reduce<string[]>(
        (legIds, trip) => [...legIds, ...trip.legs.map(({ id }) => id)],
        []
      );
    return !isEqual(currentTripLegsIds?.sort(), coveredLegIds.sort());
  }, [journeyReference, selectedOffers, tripResults]);

  const { currentTab, updateTab } = useContext(SearchTabsContext);
  const postBookingHandler = useCallback(async () => {
    const currentJourney = tripResults.find(
      ({ reference }) => journeyReference === reference
    );
    const payload = {
      offers: selectedOffers.map(({ id }) => ({
        alliances: currentJourney!.alliances!,
        id,
        passengerExternalReferences: passengerSpecifications.map(
          ({ externalReference }) => externalReference
        ),
        selections: tripsSelectionMap[id],
      })),
      passengerSpecifications,
    };
    if (currentTab?.addingAdditionalJourney) {
      try {
        await dispatch(
          addOfferToBooking({
            bookingId: currentTab.bookingId!,
            ...payload,
          })
        ).unwrap();
        updateTab({ addingAdditionalJourney: false, activeStep: 0 });
      } catch (e) {
        updateTab({ addingAdditionalJourney: false, redirectToOverview: true });
      }
    } else {
      const booking = await dispatch(postBooking(payload)).unwrap();
      await dispatch(getAdditionalOffers());
      updateTab({
        name: getBookingDestinations(booking).join(' - '),
        bookingId: booking.id,
      });
    }

    history.push('/search/checkout');
    dispatch(resetSearch());
  }, [
    tripResults,
    dispatch,
    selectedOffers,
    passengerSpecifications,
    updateTab,
    currentTab,
    history,
    journeyReference,
    tripsSelectionMap,
  ]);

  return (
    <Card sx={{ overflow: 'visible', marginBottom: '72px' }}>
      <TripsTable isOutbound />
      {!!offersTotal.currency && (
        <CartTotal offersTotal={offersTotal} isDark>
          <Button
            loading={postBookingLoading}
            disabled={offersSelectionIncomplete}
            sx={{
              background: 'white!important',
              '&:hover': {
                boxShadow: (theme) =>
                  [
                    alpha(theme.palette.action.hover, 0.2),
                    theme.palette.common.white,
                  ]
                    .map((color) => `inset 0 0 0 2rem ${color}`)
                    .join(','),
              },
            }}
            variant="outlined"
            label={<TransButton i18nKey="continueCheckout" />}
            onClick={postBookingHandler}
          />
        </CartTotal>
      )}
    </Card>
  );
};
