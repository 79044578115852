import { Dispatch, FC, SetStateAction } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { Checkbox } from '@fleet/shared/mui/Checkbox';
import { TransLabel } from 'i18n/trans/label';
import { setPdfDownload } from 'features/trip/tripActions';
import {
  SendTicketsSelection,
  TicketSelectionPayload,
} from 'components/SendTicketsSelection';
import { useDispatch, useSelector } from 'store/utils';
import { selectEmailSendAvailable } from 'features/user/userSelector';
import { downloadPdfFlagSelector } from 'features/trip/tripSelector';
import { FormApi } from 'final-form';

interface TicketFulfillmentProps {
  emailSend: boolean;
  invalid: boolean;
  setEmailSend: Dispatch<SetStateAction<boolean>>;
  purchaserEmail: string | undefined;
  showValidations: () => void;
  emailsForm: FormApi<TicketSelectionPayload, Partial<TicketSelectionPayload>>;
}

export const TicketFulfillment: FC<TicketFulfillmentProps> = ({
  emailsForm,
  emailSend,
  invalid,
  setEmailSend,
  purchaserEmail,
  showValidations,
}) => {
  const dispatch = useDispatch();
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const emailSendAvailable = useSelector(selectEmailSendAvailable);

  return (
    <>
      <Typography variant="h2">
        <TransTitle i18nKey="ticketFulfillment" />
      </Typography>
      <Stack direction="row" spacing={3} onClick={showValidations}>
        <Checkbox
          checked={downloadPdf}
          label={<TransLabel i18nKey="pdfDownload" />}
          onChange={(e) => dispatch(setPdfDownload(e.target.checked))}
          inline
        />
        {emailSendAvailable && (
          <Checkbox
            checked={emailSend}
            disabled={invalid}
            label={<TransLabel i18nKey="email" />}
            onChange={(e) => setEmailSend(e.target.checked)}
            inline
          />
        )}
      </Stack>
      {emailSend && (
        <SendTicketsSelection
          form={emailsForm}
          isSaleFlow
          purchaserEmail={purchaserEmail}
          isInline
        />
      )}
    </>
  );
};
