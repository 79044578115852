import { BookingFulfillment, SeatProperty } from 'dto/booking';

export enum PassengerCardType {
  REDUCTION_CARD = 'REDUCTION_CARD',
  LOYALTY_CARD = 'LOYALTY_CARD',
  PASS = 'PASS',
}

export interface CustomerCard {
  type: string | PassengerCardType;
  code?: string;
  number?: number;
  uniqueCardId?: string;
}

export enum TransportType {
  AIR_TRAFFIC = 'AIR_TRAFFIC',
  HIGH_SPEED_TRAIN = 'HIGH_SPEED_TRAIN',
  INTERCITY_REGIONAL = 'INTERCITY_REGIONAL',
  LONG_DISTANCE = 'LONG_DISTANCE',
  LOCAL_TRAIN = 'LOCAL_TRAIN',
  SUBWAY = 'SUBWAY',
  TRAM = 'TRAM',
  TRAMS = 'TRAMS',
  LOCAL_BUS = 'LOCAL_BUS',
  FERRY = 'FERRY',
  TAXI = 'TAXI',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
  SHIP = 'SHIP',
  SHARED_TAXI = 'SHARED_TAXI',
  UNDERGROUND = 'UNDERGROUND',
}

export type PromoCode = {
  code: string;
  issuingCarrierCode?: string;
};

export interface PassengerSpecification {
  externalReference: string;
  type: string;
  age?: number;
  concession?: {};
  cards?: Array<CustomerCard>;
  prmNeeds?: Array<string>;
}

export interface TravelPassSearchParams {
  validityStartTime: string;
  currency: string;
  originStop: {
    code: string;
  };
  destinationStop: {
    code: string;
  };
  passengerSpecifications: Array<PassengerSpecification>;
  corporateCodes: Array<PromoCode>;
  promotionCodes: Array<PromoCode>;
}

export interface TripSearchParams extends TravelPassSearchParams {
  arrivalTime: string;
  departureTime: string;
  via: {
    viaStop: {
      code: string;
    };
  };
  searchCriteria?: {
    ptModesFilter?: {
      exclude?: boolean;
      ptModes: Array<string>;
    };
    carriersFilter?: {
      exclude?: boolean;
      carriers: Array<string>;
    };
    transfersFilter?: {
      transferLimit: number;
      minTransferTime: number;
    };
  };
}

export interface PassengerCardState {
  toggledCardMap: { [key: string]: string };
  isApplyCardToAllPassengersToggleChecked: boolean;
}

export interface SearchBarFormValues extends TripSearchParams {
  cardState?: PassengerCardState;
}

export interface JourneyLink {
  rel: 'next' | 'previous';
  href: string;
}

export interface Journey {
  reference: string;
  alliances: Array<string>;
  isOutbound: boolean;
  departureTime: string;
  arrivalTime: string;
  originStop: TripStop;
  destinationStop: TripStop;
  duration: string;
  transfers: number;
  lowestPrice: {
    currency: string;
    amount: number;
  };
  validTo: string;
  trips: Array<Trip>;
}

export interface Trip {
  id: string;
  duration: string;
  departureTime: string;
  arrivalTime: string;
  originStop: TripStop;
  destinationStop: TripStop;
  legs: Array<TripLeg>;
  offers: Array<TripOffer>;
}

export interface TripStop {
  code: string;
  name: string;
  hasPrmSupport: boolean;
  cityName: string;
  countryName?: string;
}

export enum GenderOptions {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  X = 'X',
}

export enum OfferAccommodationType {
  SEAT = 'SEAT',
  BED = 'BED',
  COMPARTMENT = 'COMPARTMENT',
}

export enum OfferGenderProperties {
  LADIES = 'LADIES',
  MEN = 'MEN',
  MIXED = 'MIXED',
}

export enum OfferBedProperties {
  LOWER_BED = 'LOWER_BED',
  MIDDLE_BED = 'MIDDLE_BED',
  UPPER_BED = 'UPPER_BED',
}

export enum OfferPetsProperties {
  WITHOUT_ANIMALS = 'WITHOUT_ANIMALS',
  WITH_ANIMALS = 'WITH_ANIMALS',
  NEAR_ANIMALS = 'NEAR_ANIMALS',
}

export interface TripOffer {
  admissions: Array<{
    id: string;
    productDescription: string;
    productDescriptiveTexts: Array<{
      description: string;
      shortDescription: string;
      textElementType: string;
    }>;
    travelClass: string;
    serviceClass: {
      code: string;
      name: string;
    };
    reservationRefs?: Array<{ id: string }>;
  }>;
  productDescription?: string;
  productSummary: string;
  descriptiveProductTexts: Array<string>;
  offerAccommodationType: OfferAccommodationType;
  availablePlaces: number;
  reservationOfferParts: Array<ReservationOfferPart>;
  reservationLegCoverage: Array<ReservationLegCoverage>;
  id: string;
  price: Price;
  coveredLegIds: Array<string>;
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  conditions?: {
    type: string;
    description: string;
    fee: Price;
  };
  appliedReductions: Array<{
    type: string;
    code: string;
    description?: string;
  }>;
  fulfillments: Array<BookingFulfillment>;
  offerMode: string;
  passengers?: Array<Passenger>;
  passengerIds: Array<string>;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  flexibility: 'NON_FLEXIBLE' | 'SEMI_FLEXIBLE' | 'FULL_FLEXIBLE';
}

export interface PassengerOfferSelection {
  reservationId: string;
  tripLegCoverage: {
    tripId: string;
    legId: string;
  };
  passengerIds: Array<string>;
  accommodationType: OfferAccommodationType;
  accommodationSubType: string;
  placeProperties: Array<SeatProperty>;
}

export interface ReservationLegCoverage {
  tripId: string;
  legId: string;
  reservationId: string;
  accommodationType: OfferAccommodationType;
  accommodationSubType: string;
  numericAvailability: number;
  placeProperties: Array<SeatProperty>;
}

export interface ReservationOfferPart {
  reservationId: string;
  availablePlaces: Array<Omit<ReservationLegCoverage, 'reservationId'>>;
}

export interface Passenger {
  passengerId: number;
  externalRef: string;
  dateOfBirth?: string;
  age?: number;
  passengerCategory: string;
}

export type ServiceAttribute = {
  code: string;
  description: string;
};

export interface TripLeg {
  id: string;
  duration: string;
  departureTime: string;
  arrivalTime: string;
  originStop: TripStop;
  destinationStop: TripStop;
  carrier: {
    code?: string;
    name?: string;
  };
  ptMode: {
    code: TransportType;
    name: string;
  };
  productCategoryCode: string;
  productCategoryName: string;
  serviceCode: number;
  serviceTexts: Array<string>;
  onDemandTexts: Array<string>;
  lineNumber?: number;
  intermediateStops?: Array<{
    code: string;
    name: string;
    arrivalTime: string;
    departureTime: string;
    cityName?: string;
  }>;
  serviceAttributes: Array<ServiceAttribute>;
  reservationRequirements?: Array<string>;
  travelClass?: string;
}

export interface Price {
  currency: string;
  amount: number;
  vats?: Array<{
    amount: number;
    percentage: number;
  }>;
}

export interface TravelPass {
  id: string;
  summary: string;
  offerSummary: {
    minimalPrice: Price;
  };
  admissionOfferParts: Array<AdmissionOfferPart>;
}

interface AdmissionOfferPart {
  productDescription: string;
  productTag: string;
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  price: Price;
  odPair: OdPair;
  validFrom: string;
  validTo: string;
  refundable: string;
  exchangeable: string;
  conditions: Array<Condition>;
  appliedReductions: Array<{ type: string; code: string }>;
}

export interface Condition {
  type: string;
  description: string;
  shortDescription: string;
}

interface OdPair {
  from: {
    code: string;
    name: string;
  };
  to: {
    code: string;
    name: string;
  };
}

export enum PaymentStatus {
  pending = 'pending',
  paid = 'paid',
  not_paid = 'not_paid',
}
