import { alertProviderProps } from '@fleet/shared/components/Alert';
import { Loader, ThemeProvider } from '@fleet/shared/mui';
import { App } from 'App';
import AxiosErrorsInterceptorWrapper from 'wrappers/AxiosErrorsInterceptorWrapper';
import authConf from 'authConf';
import { initializeSentry } from 'helpers/sentryConfig';
import 'i18n';
import 'index.scss';
import React, { StrictMode, Suspense } from 'react';
import { Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { store } from 'store';
import { IS_DS_AT } from 'utils/common';

initializeSentry();

const faviconPath = [
  process.env.PUBLIC_URL,
  IS_DS_AT ? 'favicon.ico' : 'favicon-ims.ico',
].join('/');

ReactDOM.render(
  <StrictMode>
    <Favicon url={faviconPath} />
    <ThemeProvider>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider {...authConf}>
            <AlertProvider {...alertProviderProps}>
              <AxiosErrorsInterceptorWrapper>
                <App />
              </AxiosErrorsInterceptorWrapper>
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
