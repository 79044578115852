import {
  addOfferToBooking,
  getAdditionalOffers,
  postBooking,
} from './bookingActions';
import { isAfter } from 'date-fns';
import {
  BookingAdmission,
  BookingFulfillment,
  PassengerFee,
} from 'dto/booking';
import { RootState } from 'store';
import { createLoadingSelector, createSelector, selector } from 'store/utils';
import {
  getBookingAdmissions,
  getHasJourneyNotifications,
  getOnDemandServiceTexts,
} from 'utils/trip';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';

export const bookingsSearchSelector = selector((state) => state.booking.search);

export const bookingLoadingSelector = createLoadingSelector(
  postBooking,
  addOfferToBooking,
  getAdditionalOffers
);

export const currentBookingSelector = selector(
  (state: RootState) => state.booking.current
);

export const bookingFeesSelector = selector((state: RootState) => {
  const booking = currentBookingSelector(state);
  if (!booking) return [];
  return booking.bookedTrips.reduce<Array<PassengerFee>>(
    (acc, { bookedOffer }) => [...acc, ...bookedOffer.fees],
    []
  );
});

export const bookingTotalFeesSelector = selector((state: RootState) => {
  const booking = currentBookingSelector(state);
  if (!booking) return [];
  const fees = booking.bookedTrips.reduce<Array<PassengerFee>>(
    (acc, { bookedOffer }) => [...acc, ...bookedOffer.fees],
    []
  );
  const feesByDescription = groupBy(fees, 'description');
  const preparedFeesByDescription = mapValues(
    feesByDescription,
    (fees: Array<PassengerFee>) =>
      fees.reduce((acc, { price }) => ({
        ...acc,
        price: {
          ...acc.price,
          amount: acc.price.amount + price.amount,
          vats: [...(acc.price.vats ?? []), ...(price.vats ?? [])],
        },
      }))
  );
  return Object.values(preparedFeesByDescription);
});
export const currentBookingIdSelector = selector(
  (state: RootState) => state.booking.current?.id
);

export const bookingHistorySelector = selector(
  (state: RootState) => state.booking.history
);

export const bookingsFilterSelector = selector((state) => state.booking.filter);

export const bookingPartsSelector = selector(
  (state) => state.booking.selection
);

export const selectFulfillmentIdsSelection = createSelector((state) => {
  const booking = currentBookingSelector(state)!;
  const { admission, ancillary, fulfillment } = bookingPartsSelector(state);
  const [admissionIds, ancillaryIds, fulfillmentIds] = [
    admission,
    ancillary,
    fulfillment,
  ].map((selection) => Object.values(selection).flat());
  const selectedAdmissions = getBookingAdmissions(booking).filter(({ id }) =>
    admissionIds.includes(id)
  );
  return uniq([
    ...selectedAdmissions
      .map(({ fulfillments, reservations, fees }) =>
        [
          ...fulfillments,
          ...reservations.reduce<Array<BookingFulfillment>>(
            (acc, reservation) => [...acc, ...(reservation.fulfillments || [])],

            []
          ),
          ...fees.map((fee) => fee.fulfillments).flat(),
        ].map(({ id }) => id)
      )
      .flat(),
    ...ancillaryIds,
    ...fulfillmentIds,
  ]);
});

export const selectRefundOffers = selector(
  (state) => state.booking.refundOffers
);

export const selectReleaseOffers = selector(
  (state) => state.booking.releaseOffers
);

export const bookingPrebookedSelector = selector((state) => {
  const currentBooking = state.booking.current;
  return (
    !currentBooking ||
    currentBooking.bookedTrips
      .reduce<Array<BookingAdmission>>(
        (admissions, trip) => [...admissions, ...trip.bookedOffer.admissions],
        []
      )
      .every(({ status }) => status === 'PREBOOKED')
  );
});

export const bookingExpiredSelector = selector((state) => {
  const isPrebooked = bookingPrebookedSelector(state);
  const currentBooking = state.booking.current;
  if (currentBooking) {
    const isBookingCompleted =
      currentBooking.confirmableUntil === '0001-01-01T00:00:00Z';

    return (
      !isBookingCompleted &&
      isPrebooked &&
      isAfter(new Date(), new Date(currentBooking.confirmableUntil))
    );
  }
});

export const bookingCommentsSelector = selector(
  (state) => state.booking.comments
);

export const isTravelPassBookingSelector = selector(
  (state) => state.booking.current?.bookedTrips.length === 0
);

export const bookingNotificationsPresentSelector = selector((state) => {
  if (!state.booking.current) return;
  return (
    getHasJourneyNotifications(state.booking.current.bookedTrips) &&
    getOnDemandServiceTexts(state.booking.current.bookedTrips)
  );
});

export const bookingAdditionalOffersSelector = selector(
  (state) => state.booking.additionalOffers
);

export const onHoldBookingTimeSelector = selector(
  (state) => state.booking.onHoldBookingTime
);
