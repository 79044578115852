import { SeatProperty } from 'dto/booking';
import { TripOffer, PassengerOfferSelection } from 'dto/trip';
import { TransLabel } from 'i18n/trans/label';
import { FC, useCallback, useContext, useMemo } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  FieldArray,
  FormProvider,
  Modal,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useModal } from '@fleet/shared/hooks';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  clearOfferSelection,
  updateOfferSelection,
} from 'features/trip/tripActions';
import { selectSelectedOffers } from 'features/trip/tripSelector';
import { getReadablePropertyName } from 'utils/trip';
import uniqBy from 'lodash/uniqBy';
import { renderToString } from 'react-dom/server';

interface SeatPreferenceModalProps {
  offer: TripOffer;
  onClose: () => void;
}

type Selection = PassengerOfferSelection & {
  placePropertiesStr: string;
};

interface SeatPreferenceForm {
  selections: Array<Selection>;
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 776,
    },
    number: {
      color: theme.palette.text.secondary,
      '& span': {
        color: theme.palette.text.primary,
        marginRight: 4,
      },
    },
  }),
  { name: 'SeatPreferenceModal' }
);

const FORM_ID = 'seat-preferences';

export const SeatPreferenceModal: FC<SeatPreferenceModalProps> = ({
  offer,
  onClose,
}) => {
  const selectedOffersInfo = useSelector(selectSelectedOffers);
  const { reservationLegCoverage, reservationOfferParts, id } = offer;
  const { open } = useModal({ open: true });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentTab } = useContext(SearchTabsContext);
  const currentOfferSelections = useMemo(
    () =>
      selectedOffersInfo.tripsSelectionMap[id]?.map(
        ({ placeProperties, ...rest }) => ({
          placePropertiesStr: placeProperties.join(','),
          placeProperties,
          ...rest,
        })
      ),
    [id, selectedOffersInfo.tripsSelectionMap]
  );
  const passengerSpecifications = useMemo(
    () => currentTab!.params!.passengerSpecifications!,
    [currentTab]
  );

  const onSubmit = useCallback(
    (payload: SeatPreferenceForm) => {
      const preparedSelections = payload.selections
        .filter(({ placePropertiesStr }) => placePropertiesStr)
        .map(({ placePropertiesStr, ...rest }) => ({
          ...rest,
          placeProperties: placePropertiesStr?.split(',') ?? [],
        })) as Array<PassengerOfferSelection>;
      const emptySelection = preparedSelections.every(
        ({ placeProperties }) => !placeProperties?.length
      );
      if (emptySelection) {
        dispatch(clearOfferSelection(offer.id));
      } else {
        dispatch(
          updateOfferSelection({
            offerId: offer.id,
            selections: preparedSelections,
          })
        );
      }
      onClose();
    },
    [dispatch, offer.id, onClose]
  );

  const initialValues = useMemo<SeatPreferenceForm>(() => {
    const { tripId, legId, ...rest } = reservationLegCoverage[0];
    return {
      selections:
        currentOfferSelections ??
        passengerSpecifications.map(({ externalReference }, idx) => ({
          passengerIds: [externalReference],
          tripLegCoverage: {
            tripId,
            legId,
          },
          ...rest,
          reservationId: reservationOfferParts[idx].reservationId,
          placePropertiesStr: '',
        })),
    };
  }, [
    currentOfferSelections,
    passengerSpecifications,
    reservationLegCoverage,
    reservationOfferParts,
  ]);

  const { form, handleSubmit, values } = useForm<SeatPreferenceForm>({
    onSubmit,
    initialValues,
    subscription: { values: true },
  });
  const getPropertiesTranslation = useCallback(
    (placeProperties: SeatProperty[]) => {
      try {
        return placeProperties
          .map((property) => {
            try {
              return renderToString(<TransLabel i18nKey={property} />);
            } catch (e) {
              return getReadablePropertyName(property);
            }
          })
          .join(', ');
      } catch (e) {
        placeProperties.join(', ');
      }
    },
    []
  );
  const propertiesOptions = useMemo(() => {
    const options = reservationLegCoverage
      .filter(({ placeProperties }) => placeProperties.length)
      .map(({ placeProperties }) => ({
        label: getPropertiesTranslation(placeProperties),
        value: placeProperties.join(','),
      }));
    return uniqBy(options, 'value');
  }, [getPropertiesTranslation, reservationLegCoverage]);

  const applyCurrentPreferenceToOffers = useCallback(() => {
    dispatch(updateOfferSelection(values));
  }, [dispatch, values]);
  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="seatPreference" />}
      open={open}
      onClose={onClose}
      showCloseControl={false}
      actionButton={
        <Stack direction="row" spacing={2}>
          {selectedOffersInfo.trips.length > 1 && (
            <Button variant="outlined" onClick={applyCurrentPreferenceToOffers}>
              <TransButton i18nKey="applySeatPreferenceToAll" />
            </Button>
          )}
          <Button variant="contained" form={FORM_ID} type="submit">
            <TransButton i18nKey="confirmSelection" />
          </Button>
        </Stack>
      }
    >
      <FormProvider {...form}>
        <Grid
          component="form"
          id={FORM_ID}
          container
          columns={2}
          spacing={3}
          rowSpacing={3}
          onSubmit={handleSubmit}
        >
          <FieldArray<PassengerOfferSelection> name="selections">
            {({ fields }) =>
              fields.value?.map((value, idx, values) => (
                <Grid item key={idx} xs={1}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle" className={classes.number}>
                      <span>{`${idx + 1}/${values.length}`}</span>
                      <TransSubtitle i18nKey="passenger" />
                    </Typography>
                    <SelectField
                      label={<TransField i18nKey="preference" />}
                      name={`${fields.name}[${idx}].placePropertiesStr`}
                      showEmptyOption
                      options={propertiesOptions}
                    />
                  </Stack>
                </Grid>
              ))
            }
          </FieldArray>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
